:root {
    --bs-primary: #00549c;
    --bs-primary-rgb: #00549c;
}

.bg-primary {
    --bs-bg-opacity: 1;
    background-color: var(--bs-primary-rgb) !important;
}

.border-primary {
    --bs-border-opacity: 1;
    border-color: var(--bs-primary-rgb) !important;
}

.text-primary {
    --bs-text-opacity: 1;
    color: rgba(0, 84, 156, 1) !important;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--bs-primary);
    --bs-btn-border-color: var(--bs-primary);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0b5ed7;
    --bs-btn-hover-border-color: #0a58ca;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0a58ca;
    --bs-btn-active-border-color: #0a53be;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--bs-primary);
    --bs-btn-disabled-border-color: var(--bs-primary);
}

.quill {
    white-space: pre-wrap !important;
}

.text-justify {
    text-align: justify !important;
}

body {
    min-height: 100vh;
}
span.org-name {
    font-size: clamp(1rem, 9vw, 2.2rem) !important;
    font-optical-sizing: auto;
}
span.org-region {
    font-size: clamp(1rem, 4vw, 1.2rem) !important;
    font-optical-sizing: auto;
    font-weight: 400;
}
.header_info {
    color: white;
}
.mun-info {
    font-size: clamp(0.8rem, 3.6vw, 1rem) !important;
    font-optical-sizing: auto;
}

.footer-links a,
.footer-links span {
    text-decoration: none;
    color: white;
    /* font-size: 1.2rem; */
    /* font-size: clamp(1rem, 2.5vw, 2rem); */
    font-optical-sizing: auto;
}

.footer-info-header {
    font-weight: 600;
}

.block-title {
    font-weight: 600;
    font-size: clamp(1.3rem, 7.5vw, 2.3rem) !important;
    font-optical-sizing: auto;
    color: var(--bs-primary);
}

.gov-logo-useful {
    /* filter: invert(1) drop-shadow(1 1 1 #00549c) !important; */
    filter: brightness(0) saturate(100%) invert(15%) sepia(58%) saturate(6556%) hue-rotate(195deg) brightness(84%)
        contrast(108%);
    /* filter: drop-shadow(0 0 0 #00549c) !important; */
}

.icon-1 {
    font-size: clamp(0.8rem, 3vw, 1rem) !important;
    font-optical-sizing: auto;
}
.icon-2 {
    font-size: clamp(1rem, 4vw, 1.5rem) !important;
    font-optical-sizing: auto;
}
.icon-3 {
    font-size: clamp(1.5rem, 4vw, 2rem) !important;
    font-optical-sizing: auto;
}
.icon-4 {
    font-size: clamp(1.2rem, 6vw, 2.5rem) !important;
    font-optical-sizing: auto;
}
.icon-5 {
    font-size: clamp(1.9rem, 15vw, 3rem) !important;
    font-optical-sizing: auto;
}
.icon-6 {
    font-size: clamp(2rem, 17.5vw, 3.5rem) !important;
    font-optical-sizing: auto;
}

.font-0 {
    font-size: clamp(0.6rem, 3vw, 1rem) !important;
    font-optical-sizing: auto;
}
.font-1 {
    font-size: clamp(0.8rem, 3vw, 1rem) !important;
    font-optical-sizing: auto;
}
.font-2 {
    font-size: clamp(0.9rem, 5vw, 1.1rem) !important;
    font-optical-sizing: auto;
}
.font-3 {
    font-size: clamp(1.5rem, 4vw, 2rem) !important;
    font-optical-sizing: auto;
}
.font-4 {
    font-size: clamp(1.2rem, 6vw, 2.5rem) !important;
    font-optical-sizing: auto;
}
.font-5 {
    font-size: clamp(1.9rem, 15vw, 3rem) !important;
    font-optical-sizing: auto;
}
.font-6 {
    font-size: clamp(2rem, 17.5vw, 3.5rem) !important;
    font-optical-sizing: auto;
}

.resources-services-content span {
    font-size: clamp(0.775rem, 1.8vw, 1.2rem) !important;
    font-optical-sizing: auto;
}

#resources-services-tabs .nav-item button {
    font-size: clamp(0.9rem, 4vw, 1.2rem) !important;
    font-optical-sizing: auto;
}
.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus,
.react-calendar__tile.event-marked {
    background: var(--bs-primary-rgb) !important;
    color: white !important;
}

/* @media (min-width: 1630px) and (max-width: 1700px) {
    .useful-block .carousel-control-prev {
        left: -16ch !important;
    }
    .useful-block .carousel-control-next {
        right: -16ch !important;
    }
}
@media (min-width: 400px) and (max-width: 768px) {
    .useful-block .carousel-control-prev {
        left: -4.5ch !important;
    }
    .useful-block .carousel-control-next {
        right: -4.5ch !important;
    }
} */

@media (max-width: 600px) {
    footer .upper-level {
        display: flex;
        flex-direction: column !important;
    }

    .footer-info-header {
        align-items: center !important;
        justify-content: space-between !important;
    }

    .footer-links:last-child .footer-info-header:nth-child(1) {
        justify-content: center !important;
    }

    .web-site-info {
        flex-direction: column !important;
    }

    .decizion,
    .provision,
    .general-information {
        width: 100%;
    }
}

.react-calendar__tile--now {
    background: #afafaf !important;
}

.react-calendar__tile--active {
    background: var(--bg-primary);
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    background-color: var(--bs-primary) !important;
    color: var(--bs-white) !important;
}

/* .useful-block .carousel-control-prev {
    left: -8ch;
}
.useful-block .carousel-control-next {
    right: -8ch;
} */

.react-calendar {
    width: 310px !important;
}

/* .carousel-side-news {
    flex: 1 1 10ch;
} */

.bi.bi-printer.text-primary.icon-3::before {
    height: 36px;
    width: 36px;
}

.web-site-info.lower-level {
    background-color: #003562;
    color: #ffffff99 !important;
}

.nav-link {
    font-weight: bold !important;
    text-decoration: none !important;
    color: var(--bg-primary) !important;
}

.btn-outline-primary {
    --bs-btn-color: #00549c;
    --bs-btn-border-color: #00549c;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #00549c;
    --bs-btn-hover-border-color: #00549c;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #00549c;
    --bs-btn-active-border-color: #00549c;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #00549c;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #00549c;
    --bs-gradient: none;
}

.bg-third {
    background-color: #00549c33;
}

.l-u-corner-radius {
    border-radius: 0.375rem 0 0 0 !important;
}
.l-d-corner-radius {
    border-radius: 0 0 0 0.375rem !important;
}
.r-u-corner-radius {
    border-radius: 0 0.375rem 0 0 !important;
}
.r-d-corner-radius {
    border-radius: 0 0 0.375rem 0 !important;
}
.l-corner-radius {
    border-radius: 0.375rem 0 0 0.375rem !important;
}
.r-corner-radius {
    border-radius: 0 0.375rem 0.375rem 0 !important;
}
.u-corner-radius {
    border-radius: 0.375rem 0.375rem 0 0 !important;
}
.d-corner-radius {
    border-radius: 0 0 0.375rem 0.375rem !important;
}

.ql-toolbar.ql-snow {
    border-radius: 0.375rem 0.375rem 0 0 !important;
}

.add-image-switch .form-switch .form-check-input {
    padding: 0.6rem !important;
    width: 3rem !important;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-radius: 0 0 0.375rem 0.375rem !important;
}

.radius {
    border-radius: 1.5rem !important;
}

.add-news-modal .modal-dialog {
    min-width: 60vw !important;
}

.ql-editor.ql-blank,
.ql-editor {
    min-height: 20vh !important;
}

.bottom-shadow {
    box-shadow: 0px 12px 7px -17px rgb(0 0 0) !important;
}
.top-shadow {
    box-shadow: 0px -12px 7px -17px rgb(0 0 0) !important;
}

.custom-carousel-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.custom-carousel-wrapper {
    display: flex;
    width: 100%;
    position: relative;
}

.custom-carousel-content-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.custom-carousel-content {
    display: flex;
    transition: all 250ms linear;
    -ms-overflow-style: none; /* hide scrollbar in IE and Edge */
    scrollbar-width: none; /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.custom-carousel-content::-webkit-scrollbar,
.custom-carousel-content::-webkit-scrollbar {
    display: none;
}

.custom-carousel-content > * {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
}

.custom-carousel-content.show-2 > * {
    width: 50%;
}

.custom-carousel-content.show-3 > * {
    width: calc(100% / 3);
}

.custom-carousel-content.show-4 > * {
    width: calc(100% / 4);
}

.left-arrow,
.right-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: white;
    border: 1px solid #ddd;
}

.left-arrow {
    left: 24px;
}

.right-arrow {
    right: 24px;
}

@media (hover: none) and (pointer: coarse) {
    .left-arrow,
    .right-arrow {
        display: none;
    }
}

.dropdown-menu.show {
    padding-top: 0;
    padding-bottom: 0;
}

.dropdown-item:hover {
    color: var(--bs-white) !important;
    background-color: var(--bs-primary) !important;

    a {
        color: white !important;
    }
}

.dropdown-item:first-child:hover {
    border-radius: var(--bs-border-radius) var(--bs-border-radius) 0 0 !important;
}
.dropdown-item:last-child:hover {
    border-radius: 0 0 var(--bs-border-radius) var(--bs-border-radius) !important;
}

.lang .dropdown-item:hover {
    border-radius: var(--bs-border-radius) !important;
}

.dropdown-item.active,
.dropdown-item:active {
    color: var(--bs-white) !important;
    text-decoration: none;
    background-color: var(--bs-primary);
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-primary) !important;
    border-color: var(--bs-primary) !important;
}

.search-btn.btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-primary);
    border-color: var(--bs-primary);
}

.lang .btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-primary) !important;
    border-color: var(--bs-primary) !important;
}

.font-tnr {
    font-family: 'Times New Roman', Times, serif !important;
    font-optical-sizing: auto;
}
.font-onest {
    font-family: 'Onest', sans-serif !important;
    font-optical-sizing: auto;
}
.font-inter {
    font-family: 'Inter', sans-serif !important;
    font-optical-sizing: auto;
}

.custom-dropdown .dropdown-menu.show {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.player-wrapper {
    width: auto !important;
    height: auto !important;
}
.react-player {
    padding-top: 56.25% !important;
    position: relative !important;
}

.react-player > div {
    position: absolute !important;
}

.react-player > video {
    position: absolute !important;
    object-fit: fill !important;
}

.truncate {
    margin: 0; /* Убираем внешний отступ */
    line-clamp: 1; /* Число отображаемых строк */
    -webkit-line-clamp: 2; /* Число отображаемых строк */
    display: -webkit-box; /* Включаем флексбоксы */
    -webkit-box-orient: vertical; /* Вертикальная ориентация */
    overflow: hidden; /* Обрезаем всё за пределами блока */
}

.truncate-one-line {
    margin: 0; /* Убираем внешний отступ */
    line-clamp: 1; /* Число отображаемых строк */
    -webkit-line-clamp: 1; /* Число отображаемых строк */
    display: -webkit-box; /* Включаем флексбоксы */
    -webkit-box-orient: vertical; /* Вертикальная ориентация */
    overflow: hidden; /* Обрезаем всё за пределами блока */
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    flex-wrap: wrap;
    /* gap: 0.1rem; */
}
